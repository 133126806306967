<ng-container *ngIf="!order">
	<app-splash-screen></app-splash-screen>
</ng-container>

<ng-container *ngIf="order">
	<div class="order-summary sticky-top">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<div class="order-label">
						Objednávka číslo
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 text-center">
					<div class="order-number">
						{{ order.order_num }}
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="divider"></div>
				</div>
				<div class="col-12">
					<div class="order-status">
						<div class="status-update">
							<div class="status-update-indicator" [ngClass]="(orderStatus==2 || orderStatus==3)?'active':''">

							</div>
						</div>
						<div class="status-text">
							<ng-container *ngIf="orderStatus === 1">
								Nižšie môžeš sledovať stav svojej objednávky. Keď bude pripravená, môžeš si ju prísť vyzdvihnúť do reštaurácie.
							</ng-container>
							<ng-container *ngIf="orderStatus === 2">
								Tvoja objednávka z <strong>{{ orderStatusRestaurant }}</strong> je pripravená na vyzdvihnutie.
							</ng-container>
							<ng-container *ngIf="orderStatus === 3">
								Viacero tvojich objednávok je pripravených na vyzdvihnutie. Skontroluj si karty nižšie.
							</ng-container>
							<ng-container *ngIf="orderStatus === 4">
								Objednávka je vybavená. Dobrú chuť.
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


	<div class="order-summary fake">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<div class="order-label">
						Objednávka číslo
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 text-center">
					<div class="order-number">
						{{ order.order_num }}
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="divider"></div>
				</div>
				<div class="col-12">
					<div class="order-status">
						<div class="status-update">
							<div class="status-update-indicator">

							</div>
						</div>
						<div class="status-text">
							<ng-container *ngIf="orderStatus === 1">
								Nižšie môžeš sledovať stav svojej objednávky. Keď bude pripravená, môžeš si ju prísť vyzdvihnúť do reštaurácie.
							</ng-container>
							<ng-container *ngIf="orderStatus === 2">
								Tvoja objednávka z <strong>{{ orderStatusRestaurant }}</strong> je pripravená na vyzdvihnutie.
							</ng-container>
							<ng-container *ngIf="orderStatus === 3">
								Viacero tvojich objednávok je pripravených na vyzdvihnutie. Skontroluj si karty nižšie.
							</ng-container>
							<ng-container *ngIf="orderStatus === 4">
								Objednávka je vybavená. Dobrú chuť.
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="order-detail">
		<div class="container">
			<div *ngIf="order.date" class="row">
				<div class="col-12 text-right whole-order-time">
					<span class="order-time-label">
						Čas objednávky:
					</span>
					<span class="order-time-value">
						{{ order.date | date :'dd.MM. HH:mm' }}
					</span>
				</div>
			</div>
			<div class="row" *ngFor="let orderItem of order.orders">
				<app-restaurant-order class="restaurant-order-item container" [order]="orderItem"></app-restaurant-order>
			</div>
		</div>
	</div>
</ng-container>
