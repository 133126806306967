import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, pipe } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NgxSpinnerService } from "ngx-spinner";

import { StatusService } from 'src/app/services/status.service';
import { RestaurantOrderComponent } from 'src/app/modules/restaurant-order/restaurant-order.component';

@Component({
  	selector: 'app-order-status',
  	templateUrl: './order-status.component.html',
  	styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit {

    order: any;
	orders: any;

    orderStatus: Number = 1;
    orderStatusRestaurant: string = '';

    token: any;

  	constructor(
        private statusService: StatusService,
        private route: ActivatedRoute,
        private router: Router,
        private spinner: NgxSpinnerService,
    ) { }

  	ngOnInit(): void {
        let self = this;
        this.route.params.subscribe(parameter => {
            if(parameter.token){

                self.spinner.show();
                self.token = parameter.token;
                self.statusService.getInitailOrderStatus(parameter.token).then(function(response: any){
                    self.spinner.hide();
                    self.order = response.order;

                    self.order.orders.forEach(function(order:any){
                        //console.log(order.id);
                        self.statusService.create_websocket_sub_by_id(order.id);
                    });
                });
            }
        });

/*
        this.order = {
            "token":"eadeb98e7c1d5f9167d7d5582b5b9c8c",
            "order_num":"K1-5",
            "date":"2021-01-21 12:45:07",
            "orders":[
                {
                    "id":13,"status":"ordered","location_id":2,"order_num":"K1-5","date":"2021-01-21 12:45:07","location_logo":"https:\/\/tracware.store\/uploads\/business_logos\/Poke-bistro-logo.png","location_name":"Pok\u00e9 Bistro"
                },
                {
                    "id":14,"status":"accepted","location_id":3,"order_num":"K1-5","date":"2021-01-21 12:45:07","location_logo":"https:\/\/tracware.store\/uploads\/business_logos\/chilantro.png","location_name":"Barskde & Chilantro"
                }
            ]
        }
        */
        //this.orders = this.order.orders;



        self.statusService.orderStatusUpdate.subscribe({
            next: (v) => {
                self.updateChildOrderStatus(v);
            }
        });

        self.statusService.socketConnected.subscribe({
            next: (v) => {
                this.getDataFromApi(self.order.token);
            }
        });


        //this.statusService.create_websocket_sub();
  	}

    getDataFromApi(token){
        let self = this;
        self.spinner.show();
        self.statusService.getInitailOrderStatus(token).then(function(response: any){
            let dateParts = response.order.date.split(' ');
            response.order.date = dateParts[0]+'T'+dateParts[1]+'Z';
            response.order.orders.forEach((item) => {
                let suborderDateParts = item.date.split(' ');
                item.date = suborderDateParts[0]+'T'+suborderDateParts[1]+'Z';
            })
            self.spinner.hide();
            self.order = response.order;
        });
    }

    updateChildOrderStatus(order){

        let updatedOrder = order;
        let matchedOrder = this.order.orders.filter(matchedOrder => matchedOrder["id"] === parseInt(updatedOrder.id));
        matchedOrder[0].status = updatedOrder.status;

        let clonedOrders = [];
        this.order.orders.forEach(val => {
            clonedOrders.push(Object.assign({}, val))
        });
        this.order.orders = clonedOrders;
        this.updateParentOrderStatus();
    }

    updateParentOrderStatus(){
        this.orderStatusRestaurant = '';
        let readyOrders = this.order.orders.filter(order => order["status"] === 'done');
        if(readyOrders.length == 0){
            if(this.order.orders.filter(order => order["status"] === 'taken').length){
                this.orderStatus = 4;
            }else{
                this.orderStatus = 1;
            }
        }
        if(readyOrders.length == 1){
            this.orderStatus = 2;
            this.orderStatusRestaurant = readyOrders[0].location_name;
        }
        if(readyOrders.length >= 2){
            this.orderStatus = 3;
            this.orderStatusRestaurant = readyOrders[0].location_name;
        }
    }

    ngOnDestroy(){
        this.closeSocket();
    }

    closeSocket(){
        this.statusService.closeSocket();
    }

}
