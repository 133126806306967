import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';

@Component({
  	selector: 'app-restaurant-order',
  	templateUrl: './restaurant-order.component.html',
  	styleUrls: ['./restaurant-order.component.scss']
})
export class RestaurantOrderComponent implements OnInit {

	@Input() order: any;

  	constructor() { }

  	ngOnInit(): void {
  		console.log(this.order);
  	}

  	ngOnChanges(changes: SimpleChanges) {
    	//console.log(changes.order);
  	}

}
