import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OrderStatusComponent } from './pages/order-status/order-status.component';
import { RestaurantOrderComponent } from './modules/restaurant-order/restaurant-order.component';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { MainOrderMessagePipe } from './pipes/main-order-message.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  	declarations: [
    	AppComponent,
    	OrderStatusComponent,
    	RestaurantOrderComponent,
    	OrderStatusPipe,
    	SplashScreenComponent,
    	MainOrderMessagePipe
  	],
  	imports: [
    	BrowserModule,
    	AppRoutingModule,
    	NgbModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
  	],
  	providers: [],
  	bootstrap: [AppComponent]
})
export class AppModule { }
