import { Injectable } from '@angular/core';
import { fromEvent, Observable, timer } from 'rxjs';
import { mergeMap, retryWhen, take } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { EnvService } from './env.service';


const SCALING_DURATION = 500;
const MAX_DURATION = 30000;


@Injectable({
  	providedIn: 'root'
})
export class SocketService {

  	private socketServer = this.env.socketUrl;
  	socket$: Observable<any>;

  	constructor(private env: EnvService) {
	    this.socket$ = new WebSocketSubject(this.socketServer).pipe(
	      	retryWhen((attempts) =>
		        attempts.pipe(
		          	mergeMap((_, i) => {
		            	if (window.navigator.onLine) {
		              		const retryAttempt = i + 1;
		              		return timer(
		                		Math.min(retryAttempt * SCALING_DURATION, MAX_DURATION)
		              		);
		            	} else {
		              		return fromEvent(window, 'online').pipe(take(1));
		            	}
		          	})
		        )
	      	)
	    );
  	}

}
