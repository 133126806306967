import { Injectable } from '@angular/core';

@Injectable({
  	providedIn: 'root'
})
export class EnvService {

	//apiUrl = 'https://kioskapi.tracware.store/api/';
	apiUrl = 'https://stageupos.tracware.store/api/';

	socketUrl = "wss://ws.vps.tracware.eu";

  	constructor() { }
}
