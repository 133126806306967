<div class="restaurant-order-wrapper">
	<div class="row">
		<div class="col-8">
			<div class="restaurant-name">
				{{ order.location_name }}
			</div>
		</div>
		<div class="col-4 float-right text-right">
			<div class="restaurant-logo">
				<img class="image" src="{{ order.location_logo }}" alt="{{ order.location_name }}">
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<div class="order-status-wrapper">
				<div *ngIf="order.status" class="row">
					<div class="col-12">
						<div class="order-status-title">
							{{ (order.status | orderStatus).title }}
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="order-status-bar">
							<div class="order-status-bar-part status-segment-first" [class.completed]="(order.status | orderStatus).id > 0" [class.in-progress]="(order.status | orderStatus).id === 0">

							</div>
							<div class="order-status-bar-part status-segment-center" [class.completed]="(order.status | orderStatus).id > 1" [class.in-progress]="(order.status | orderStatus).id === 1">

							</div>
							<div class="order-status-bar-part status-segment-last" [class.completed]="(order.status | orderStatus).id > 2" [class.in-progress]="(order.status | orderStatus).id === 2">

							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="divider"></div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="order-status-dot"></div>
						<div class="order-status-text">
							<!--
								Objednávka bude pripravená asi o <span class="order-status-time-value">9:50</span>
							-->
							Objednávka vytvorená: <span class="order-status-time-value">{{ order.date | date :'dd.MM. HH:mm' }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
