import { Injectable, Input, Output, EventEmitter } from '@angular/core';
import { EMPTY, Observable, Subject, Subscription, of, BehaviorSubject, from, } from 'rxjs';
import {  } from 'rxjs';
import { catchError, map, tap, switchAll, debounceTime, filter, reduce, switchMap, takeUntil, toArray } from 'rxjs/operators';
import { FormControl, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { NgbModal, NgbModalConfig, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { EnvService } from './env.service';
import { SocketService } from 'src/app/services/socket.service';

@Injectable({
  	providedIn: 'root'
})
export class StatusService {
	webSocketUrl = 'wss://ws.vps.tracware.eu';
	webSocket: WebSocketSubject<any> = webSocket('wss://ws.vps.tracware.eu');
	webSocketPing: any;

	s_sub: any;

	orderStatusUpdate = new Subject<any>();
	socketConnected = new Subject<any>();

  	constructor(
  		private http: HttpClient,
  		private env: EnvService,
  		private modalService: NgbModal,
  		private socket: SocketService,
  	) { }

  	getInitailOrderStatus(token: string){
		const promise = new Promise((resolve, reject) => {
			let body = {
				'token': token
			};
	  		this.http.post(this.env.apiUrl + 'status', body,{}).toPromise().then((res: any) => {

		    	resolve(res);
		    })
  		})
  		return promise;
	}

	create_websocket(name:string, funAfter:any) {
	    console.log(name+' creating WebSocket');
	    let self = this;
	    var socket = new WebSocket(this.webSocketUrl);
	    socket.onopen = function(event) {
	        console.log(name+" WebSocket connection open.");
	        socket.send('{"event":"'+name+'_client_connected"}');
	        //keepalive(s);
	        funAfter(socket);
	    };
	    socket.onclose = function(event) {
	        console.log(name+" WebSocket connection closed.");
	        //  clearInterval(intervalId);
	    };
	    socket.onerror = function(event) {
	        console.error(name+" WebSocket error observed:", event);
	        //  clearInterval(intervalId);
	    };
	    console.log(name+' adding onmessage handler');
	    socket.onmessage = function(event) {
	        console.log(name+' onmessage: ', event);
	        if (event.data === "refresh") {
	            location.reload();
	        }
	    };
	    console.log(name+' end');
	    return socket;
	}
/*
	create_websocket_sub() {
		let self = this;
	    console.log("create_websocket_sub()");
	    this.create_websocket('sub', function(socket:any){
	        console.log("sub funAfter");
	        self.s_sub = socket;
	//      s_sub.onopen = function(event){
	            console.log("sub onopen event:", event);
	            self.s_sub.send('{"event":"sub_client_connected"}');
	            self.s_sub.send('{"event":"obj_sub", "type":"order", "id":"1"}');
	//      };
	        self.s_sub.onmessage = function(event: any) {
	            console.log("sub onmessage event:", event);
	            var o = JSON.parse(event.data);
	            if (o['event'] == 'obj_pub' && o['type'] == 'order') {
	                console.log(o['obj']);
	            }
	        };
	        socket.onclose = function(event:any) {
	            console.log("connection closed -> reconnecting...");
	            setTimeout(() => {
	                self.create_websocket_sub();

	            }, 3000);
	        };
	    });
	}
*/
	create_websocket_sub_by_id(id:number) {
		let self = this;
	    //console.log("create_websocket_sub()");
	    this.create_websocket('sub', function(socket:any){
	        //console.log("sub funAfter");
	        self.s_sub = socket;
            //console.log("sub onopen event:", event);
            self.s_sub.send('{"event":"sub_client_connected"}');
            self.s_sub.send('{"event":"obj_sub", "type":"order", "id":'+id+'}');
	        self.s_sub.onmessage = function(event: any) {
	            console.log("sub onmessage event:", event);
	            var o = JSON.parse(event.data);
	            if (o['event'] == 'obj_pub' && o['type'] == 'order') {
	                //console.log(o['obj']);
	                self.orderStatusUpdate.next(o['obj']);
	            }
	        };
	        socket.onclose = function(event:any) {
	            console.log("connection closed -> reconnecting...");
	            setTimeout(() => {
	            	self.create_websocket_sub_by_id(id);
	            	self.socketConnected.next();
	            }, 3000);
	        };
	        setInterval(function(){
				console.log('ping socket');
				self.s_sub.send('');
			}, 10000);
	    });
	}

	//this is not being used
	subscribeToSocket(){
		let self = this;
		this.webSocket.subscribe(    
		   	msg => console.log('message received: ' + msg), 
		   	// Called whenever there is a message from the server    
		   	err => console.log('error'), 
		   	// Called if WebSocket API signals some kind of error    
		   	() => console.log('complete') 
		   	// Called when connection is closed (for whatever reason)  
		);


		//this.webSocket.next('{"event":"sub_client_connected"}');
            //s_sub.send('{"event":"obj_sub", "type":"order", "id":"1"}');
		/*
		this.webSocketPing = setInterval(function(){
			console.log('ping socket');
			self.webSocket.next('');
		}, 3000);
		*/
	}

	closeSocket(){
		//clearInterval(this.webSocketPing);
		this.webSocket.complete();
	}
}
