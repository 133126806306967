import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrderStatusComponent } from './pages/order-status/order-status.component';

const routes: Routes = [

	{ path: '', component: OrderStatusComponent },
	{ path: ':token', component: OrderStatusComponent },

];

@NgModule({
  	imports: [RouterModule.forRoot(routes)],
  	exports: [RouterModule]
})

export class AppRoutingModule { }
