import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  	transform(status: string): any {
  		let statusObj = {
			title: '',
			id: 0
		};
  		if(status === 'ordered'){
  			statusObj = {
  				title: 'Vytvorená',
  				id: 0
  			};
    		return statusObj;
    	}

    	if(status === "accepted"){
  			statusObj = {
  				title: 'Pripravuje sa',
  				id: 1
  			};
    		return statusObj;
    	}

    	if(status === 'done'){
  			statusObj = {
  				title: 'Pripravená na vyzdvihnutie',
  				id: 2
  			};
    		return statusObj;
    	}

    	if(status === 'taken'){
  			statusObj = {
  				title: 'Odovzdaná',
  				id: 3
  			};
    		return statusObj;
    	}

    	//return statusObj;
  	}

}
